<template>
    <div class="waiting" v-if="translates && translates[langUrl]">
        <div class="waiting__survey">
            <div class="waiting__survey__titleContainer">
                <div class="waiting__survey__title">
                    {{ translates[langUrl].survey_title[lang] }}
                    <v-tooltip bottom class="waiting__survey__tooltip">
                        <template v-slot:activator="{ on, attrs }">
                            <img v-bind="attrs" v-on="on" src="../../assets/info.svg">
                        </template>
                        <span>{{ translates[langUrl].survey_tooltip[lang] }}</span>
                    </v-tooltip>
                </div>
                <div class="waiting__survey__previewButton" @click="preview">
                    <img class="waiting__survey__previewButton__eye" src="../../assets/Eye.svg" /> 
                    <div class="waiting__survey__previewButton__text">{{ translates[langUrl].button_preview[lang] }}</div>
                </div>
            </div>
            <div class="waiting__survey__mainContainer">
                <div class="waiting__survey__mainContainer__step">
                    <div class="waiting__survey__mainContainer__step__number">01</div>
                    <div class="waiting__survey__mainContainer__step__title">
                        {{ translates[langUrl].survey_step_first_title[lang] }}
                    </div>
                    <template v-if="waitingListLink">
                        <div class="waiting__survey__mainContainer__step__link">
                            {{ waitingListLink }}
                        </div>
                        <BaseButton
                            class="waiting__survey__mainContainer__step__button button-fill"
                            @click="linkCopy">
                            <img src="../../assets/copy.svg">
                            {{ translates[langUrl].button_linkCopy[lang] }}
                        </BaseButton>
                    </template>
                    <div class="waiting__survey__mainContainer__step__switcher">
                        <div class="waiting__survey__mainContainer__step__switcher__title">{{ translates[langUrl].waitingList_custom[lang] }}</div>
                        <v-switch style="margin-top: 0;"
                            :input-value="customForm"
                            @change="changeCustomisation"
                            hide-details
                        />
                    </div>
                </div>
                <div class="waiting__survey__mainContainer__arrow"></div>
                <div class="waiting__survey__mainContainer__step">
                    <div class="waiting__survey__mainContainer__step__number">02</div>
                    <div class="waiting__survey__mainContainer__step__title">
                        {{ translates[langUrl].survey_step_second_title[lang] }}
                    </div>
                    <div class="waiting__survey__mainContainer__step__description">
                        {{ translates[langUrl].survey_step_second_description[lang] }}
                    </div>
                </div>
                <div class="waiting__survey__mainContainer__arrow"></div>
                <div class="waiting__survey__mainContainer__step">
                    <div class="waiting__survey__mainContainer__step__number">03</div>
                    <div class="waiting__survey__mainContainer__step__title">
                        {{ translates[langUrl].survey_step_third_title[lang] }}
                    </div>
                    <div class="waiting__survey__mainContainer__step__description">
                        {{ translates[langUrl].survey_step_third_description[lang] }}
                    </div>
                </div>
            </div>
        </div>
        <div class="waiting__results">
            <div class="waiting__results__row">
                <div class="waiting__results__title">
                    {{ translates[langUrl].address_list[lang] }}
                </div>
                <div class="waiting__results__switch">
                    <span v-html="translates[langUrl].address_conter[lang]"></span>
                    {{ waitingLists.length }}
                </div>
            </div>
            <div class="waiting__results__header">
                <div class="waiting__results__header__item name">
                    {{ translates[langUrl].waitingList_name[lang] }}
                </div>
                <div class="waiting__results__header__item email">
                    {{ translates[langUrl].waitingList_email[lang] }}
                </div>
                <div class="waiting__results__header__item phone">
                    {{ translates[langUrl].waitingList_phone[lang] }}
                </div>
                <div class="waiting__results__header__item date">
                    {{ translates[langUrl].waitingList_date[lang] }}
                </div>
            </div>
            <template v-for="(contacts, index) in waitingLists">
                <div :key="'contacts' + index" class="waiting__results__item">
                    <div class="waiting__results__item__name">
                        <div class="waiting__results__item__textfieldName">{{ translates[langUrl].waitingList_name[lang] }}:</div> {{ contacts.name }}
                    </div>
                    <div class="waiting__results__item__email">
                        <div class="waiting__results__item__textfieldName">{{ translates[langUrl].waitingList_email[lang] }}:</div> {{ contacts.email }}
                    </div>
                    <div class="waiting__results__item__phone">
                        <div class="waiting__results__item__textfieldName">{{ translates[langUrl].waitingList_phone[lang] }}:</div> {{ contacts.phone }}
                    </div>
                    <div class="waiting__results__item__date">
                        <div class="waiting__results__item__textfieldName">{{ translates[langUrl].waitingList_date[lang] }}:</div> {{ dateFormatted(contacts.createdAt) }}
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    import store from '@/store';
    import moment from '@/plugins/moment'
    import { mapState } from 'vuex';
    import { isMobile } from '@/helpers';

    import BaseButton from '../../components/common/BaseButton.vue';
    import BaseAlertDialog from '../../components/common/BaseAlertDialog.vue';
    import PopupRequest from '../../components/common/PopupRequest.vue';
    
    export default {
        name: 'Waiting',
        metaInfo() {
            return {
                title: this.translates[this.langUrl] ? this.translates[this.langUrl].browserTitle[this.lang] : ''
            }
        },
        components: {
            BaseButton,
            BaseAlertDialog,
            PopupRequest
        },
        computed: {
            ...mapState('settings', ['lang']),
            ...mapState('auth', ['user']),
            ...mapState('translates', {
                translates: state => state.entities
            }),
            ...mapState('waitingLists', {
                waitingLists: state => state.entities
            }),
            // waitingListLink() {
            //     return `${process.env.VUE_APP_CURRENT_URL}/waiting-form/${this.user.social || null}`;
            // },
            waitingListLink() {
                return `${process.env.VUE_APP_CURRENT_URL}/waiting-form/${this.user.social || null}/${this.customFormValue}`;
            },
        },
        data: () => ({
            isMobile,
            isDesktop: false,
            loaded: false,
            canRequest: false,
            popupRequest: false,
            customForm: false,
            customFormValue: 'common',
            langUrl: '/blogger/waiting'
        }),
        created() {
            window.addEventListener('resize', this.onResize);
            this.onResize();
            // console.log(this.getWaitingLists())
        },
        destroyed() {
            window.removeEventListener('resize', this.onResize);
        },
        async mounted() {
            this.$root.$emit('preloaderShow');
            await store.dispatch('auth/fetch');
            await this.getWaitingLists();
            this.loaded = true;
            this.$root.$emit('preloaderHide');
        },
        methods: {
            async goTo(name) {
                if(name === 'trips-request') {
                    localStorage.setItem('hideTripRequestPopup', true);
                }
                await this.$router.push({ name });
            },
            dateFormatted(date) {
                return moment(date).format('L')
            },
            async getWaitingLists() {
                await store.dispatch('waitingLists/fetch', {});
            },
            onResize() {
                this.isDesktop = window.innerWidth >= 768;
            },
            async linkCopy() {
                navigator.clipboard.writeText(this.waitingListLink);
            },
            async preview() {
                window.open(this.waitingListLink, '_blank');
            },
            changeCustomisation(value) {
                this.customForm = value
                if(this.customForm === true) {
                    this.customFormValue = 'blogger'
                } else {
                    this.customFormValue = 'common'
                }
            }
        },
    };
</script>

<style lang="scss">
.waiting {
    &__header {
        position: relative;
        width: 100%;
        height: 240px;
        background: url('../../assets/bloggers/audience-bg.png') center center no-repeat;
        background-size: cover;
        border-radius: 0px 0px 12px 12px;
        overflow: hidden;

        @media all and (max-width: 768px) {
            display: flex;
            justify-content: center;
            align-items: center;
            background: url('../../assets/bloggers/audience-bg-mobile.png') center center no-repeat;
            background-size: cover;
        }

        &__title {
            position: absolute;
            bottom: 0;
            left: 0;
            padding: 40px;
            font-weight: 700;
            font-size: 38px;
            line-height: 46px;
            color: #FFFFFF;
            
            @media all and (max-width: 768px) {
                position: relative;
                font-weight: 800;
                font-size: 24px;
                line-height: 150%;
                text-align: center;
                letter-spacing: -0.02em;
            }
        }
    }

    &__quiz-link {
        height: 125px;
        position: absolute;
        bottom: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        padding: 12px 20px 20px;
        font-weight: 600;
        font-size: 18px;
        line-height: 140%;
        color: #FFFFFF;
        background: linear-gradient(270deg, rgba(225, 23, 85, 0.48) 0%, rgba(225, 23, 85, 0) 100%);
            
        @media all and (max-width: 768px) {
            height: 90px;
            padding: 20px;
            position: relative;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            font-size: 16px;
            color: #273155;
            background: #FFFFFF;

            .base-button {
                margin-left: 20px;
            }
        }
    }

    &__content {
        padding: 50px 0;
        max-width: 992px;
        margin: 0 auto;

        @media all and (max-width: 768px) {
            background: #FFFFFF;
            padding: 30px 20px 10px;
        }

        h3 {
            margin: 0;
            padding: 10px 0;
            font-weight: 600;
            font-size: 20px;
            line-height: 140%;
            color: #273155;
            
            @media all and (max-width: 768px) {
                padding: 10px 0;
                font-weight: 700;
                font-size: 28px;
                line-height: 34px;
                color: #273155;
            }
        }
        p {
            margin: 0;
            padding: 10px 0;
            font-weight: 400;
            font-size: 16px;
            line-height: 140%;
            color: #616D89;
            &.link {
                color: #3107D8;
                text-decoration: underline;
                cursor: pointer;
            }
        }
        &__buttons {
            display: flex;
            flex-direction: row;
            margin-top: 22px;
            @media all and (max-width: 768px) {
                flex-direction: column;
            }
            .button-fill {
                margin-right: 16px;
                @media all and (max-width: 768px) {
                    margin-right: 0;
                    margin-bottom: 16px;
                    width: 100%;
                }
            }
            button {
                @media all and (max-width: 768px) {
                    width: 100%;
                }
            }
        }
    }

    &__canRequest {
        width: 100%;
        height: 52px;
        border-radius: 8px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 40px 0 0 0;

        .button-fill {
            width: 100%;
        }
    }

    &__needAnswers {
        width: 100%;
        height: 52px;
        border: 1px #E61E4D solid;
        border-radius: 8px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px;
        margin: 40px 0 0 0;
        @media all and (max-width: 768px) {
            height: unset;
            margin: 20px 14px;
            width: calc(100% - 28px);
        }
        &__lightning {
            margin-right: 16px;
        }
        &__text {
            font-weight: 600;
            font-size: 16px;
            line-height: 200%;
            color: #E61E4D;
        }
    }

    &__survey {
        margin: 40px 0 60px 0;
        @media all and (max-width: 768px) {
            margin: 0 14px 40px;
        }

        &__titleContainer {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 20px;
            @media all and (max-width: 768px) {
                margin: 0 14px;
                flex-direction: column;
            }   
        }
        &__title {
            display: flex;
            justify-content: center;
            font-weight: 600;
            font-size: 28px;
            line-height: 140%;
            color: #273155;
            img {
                width: 22px;
                margin-left: 12px;
            }
            @media all and (max-width: 768px) {
                margin-bottom: 20px;
            }
        }
        &__tooltip {
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            color: #273155;
            background: #FFFFFF;
            border-radius: 12px;
            border: 1px solid #273155;
        }
        &__previewButton {
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 36px;
            padding: 8px 16px;
            border: 1px solid #A1A4B1;
            border-radius: 8px;
            cursor: pointer;

            &__eye {
                width: 20px;
                height: 12px;
                margin-right: 10px;
            }
            &__text {
                font-weight: 400;
                font-size: 14px;
                line-height: 140%;
                text-align: center;
                color: #A1A4B1;

            }
        }
        &__mainContainer {
            background: #F8F8F8;
            border-radius: 16px;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;
            width: 100%;
            padding: 40px;
            @media all and (max-width: 768px) {
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }

            &__step {
                width: 304px;
                overflow: visible;
                position: relative;
                @media all and (max-width: 768px) {
                    width: 100%;
                }

                &__title {
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 140%;
                    color: #273155;
                    margin-bottom: 5px;
                }

                &__description {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 150%;
                    color: #616D89;
                }

                &__link {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 140%;
                    color: #616D89;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    border-radius: 12px;
                    border: 2px solid #d5dae2;
                    padding: 12px;
                    margin: 15px 0;
                    white-space: nowrap;
                }

                &__button {
                    width: 100%;
                    height: 56px !important;
                    img {
                        margin-right: 12px;
                    }
                }

                &__number {
                    position: absolute;
                    width: 51px;
                    height: 38px;
                    top: -20px;
                    left: -20px;
                    font-weight: 700;
                    font-size: 42px;
                    line-height: 100%;
                    color: #000000;
                    opacity: 0.04;
                }

                &__switcher {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    height: 50px;
                    &__title {
                        margin-top: 3px;
                        font-weight: bold;
                        font-size: 20px;
                        line-height: 17px;
                        letter-spacing: -0.02em;
                        // color: #9FA5AD;
                    }
                }
            }

            &__arrow {
                width: 16px;
                height: 100px;
                background: url('../../assets/ArrowPink.svg') bottom center no-repeat;
                background-size: contain;
                @media all and (max-width: 768px) {
                    transform: rotate(90deg);
                    background-position-y: 50px;
                }
            }
        }
    }

    &__results {
        -ms-user-select: none; 
		-moz-user-select: none; 
		-webkit-user-select: none; 
		user-select: none; 
        
        &__row {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            margin: 0 -16px;
            
            @media all and (max-width: 768px) {
                margin: 0 14px;
            }
        }
        &__col {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            width: calc(50% - 32px);
            margin: 0 16px;
            
            @media all and (max-width: 768px) {
                width: calc(100% - 12px);
                margin: 0 6px;
            }
        }
        &__title {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: 16px;
            font-weight: 600;
            font-size: 28px;
            line-height: 140%;
            color: #273155;
            
            @media all and (max-width: 768px) {
                margin: 0 6px 10px;
                font-weight: 600;
                font-size: 16px;
                line-height: 140%;
                color: #273155;
            }
        }
        &__switch {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 16px;
            
            @media all and (max-width: 768px) {
                margin: 10px 6px;
            }

            & > span {
                margin-right: 12px;
                font-weight: 400;
                font-size: 16px;
                line-height: 150%;
                text-align: right;
                letter-spacing: -0.02em;
                color: #A1A4B1;

                @media all and (max-width: 768px) {
                    text-align: left;
                }
            }
        }
        &__header {
            padding: 8px 32px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 12px;
            @media all and (max-width: 768px) {
                display: none;
            }
            &__item {
                font-weight: 400;
                font-size: 16px;
                line-height: 150%;
                letter-spacing: -0.02em;
                color: #A1A4B1;

                &.name {
                    width: 30%;
                }
                &.email {
                    width: 30%;
                }
                &.phone {
                    width: 30%;
                }
                &.date {
                    width: 10%;
                }
            }
        }
        &__item {
            padding: 20px 32px;
            background: #FFFFFF;
            box-shadow: 0px 4px 16px rgba(44, 47, 56, 0.05);
            border-radius: 12px;
            margin-bottom: 12px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            @media all and (max-width: 768px) {
                flex-direction: column;
                padding: 15px;
                margin: 0 20px 12px;
                overflow: scroll;
            }
            &:last-of-type {
                margin-bottom: 0;
            }
            &__textfieldName {
                margin-right: 5px;
                min-width: 80px;
                color: #A1A4B1;
                @media all and (min-width: 769px) {
                    display: none;
                }
            }
            &__name {
                display: flex;
                width: 30%;
                font-weight: 400;
                font-size: 18px;
                line-height: 180%;
                @media all and (max-width: 768px) {
                    width: 100%;
                }
            }
            &__email {
                display: flex;
                width: 30%;
                font-weight: 600;
                font-size: 18px;
                line-height: 140%;
                @media all and (max-width: 768px) {
                    width: 100%;
                }
            }
            &__phone {
                display: flex;
                width: 30%;
                font-weight: 400;
                font-size: 18px;
                line-height: 180%;
                @media all and (max-width: 768px) {
                    width: 100%;
                }
            }
            &__date {
                display: flex;
                width: 10%;
                font-weight: 400;
                font-size: 18px;
                line-height: 180%;
                @media all and (max-width: 768px) {
                    width: 100%;
                }
            }
        }
    }
}
</style>